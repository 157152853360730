// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.equalizer {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    width: 16px; /* Adjust width based on the size of the bars */
    height: 18px; /* Adjust height based on desired size */
  }
  
  .bar {
    width: 2px;
    height: 100%; /* Full height, but we animate the scaleY */
    background-color: black; /* Change this color as needed */
    animation: equalizerBar 1.2s infinite ease-in-out;
    transform-origin: bottom; /* Anchors the scaling animation to the bottom */
  }
  
  .bar:nth-child(1) {
    animation-delay: -0.6s;
  }
  .bar:nth-child(2) {
    animation-delay: -0.48s;
  }
  .bar:nth-child(3) {
    animation-delay: -0.23s;
  }
  .bar:nth-child(4) {
    animation-delay: -0.15s;
  }
  .bar:nth-child(5) {
    animation-delay: 0s;
  }
  
  @keyframes equalizerBar {
    0%, 100% {
      transform: scaleY(0.3); /* Bar height at its smallest */
    }
    50% {
      transform: scaleY(0.9); /* Bar height at its tallest */
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/components/EqualizerIcon.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,qBAAqB;IACrB,8BAA8B;IAC9B,WAAW,EAAE,+CAA+C;IAC5D,YAAY,EAAE,wCAAwC;EACxD;;EAEA;IACE,UAAU;IACV,YAAY,EAAE,2CAA2C;IACzD,uBAAuB,EAAE,gCAAgC;IACzD,iDAAiD;IACjD,wBAAwB,EAAE,gDAAgD;EAC5E;;EAEA;IACE,sBAAsB;EACxB;EACA;IACE,uBAAuB;EACzB;EACA;IACE,uBAAuB;EACzB;EACA;IACE,uBAAuB;EACzB;EACA;IACE,mBAAmB;EACrB;;EAEA;IACE;MACE,sBAAsB,EAAE,+BAA+B;IACzD;IACA;MACE,sBAAsB,EAAE,8BAA8B;IACxD;EACF","sourcesContent":[".equalizer {\r\n    display: flex;\r\n    align-items: flex-end;\r\n    justify-content: space-between;\r\n    width: 16px; /* Adjust width based on the size of the bars */\r\n    height: 18px; /* Adjust height based on desired size */\r\n  }\r\n  \r\n  .bar {\r\n    width: 2px;\r\n    height: 100%; /* Full height, but we animate the scaleY */\r\n    background-color: black; /* Change this color as needed */\r\n    animation: equalizerBar 1.2s infinite ease-in-out;\r\n    transform-origin: bottom; /* Anchors the scaling animation to the bottom */\r\n  }\r\n  \r\n  .bar:nth-child(1) {\r\n    animation-delay: -0.6s;\r\n  }\r\n  .bar:nth-child(2) {\r\n    animation-delay: -0.48s;\r\n  }\r\n  .bar:nth-child(3) {\r\n    animation-delay: -0.23s;\r\n  }\r\n  .bar:nth-child(4) {\r\n    animation-delay: -0.15s;\r\n  }\r\n  .bar:nth-child(5) {\r\n    animation-delay: 0s;\r\n  }\r\n  \r\n  @keyframes equalizerBar {\r\n    0%, 100% {\r\n      transform: scaleY(0.3); /* Bar height at its smallest */\r\n    }\r\n    50% {\r\n      transform: scaleY(0.9); /* Bar height at its tallest */\r\n    }\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
