// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* For new items fading in */
  .fade-slide-enter {
    opacity: 0.25;
    transform: translateY(-12px);
  }

  .fade-slide-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 400ms, transform 400ms;
  }
  
  /* For old items sliding down */
  .fade-slide-exit {
    opacity: 1;
    transform: translateY(0);
  }
  .fade-slide-exit-active {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 500ms, transform 500ms;
  }
  
  /* .fade-slide-exit-active {
    opacity: 0;
    transform: translateY(-20px);
    transition: opacity 500ms ease-in, transform 500ms ease-in;
  } */
  `, "",{"version":3,"sources":["webpack://./src/components/SongList.css"],"names":[],"mappings":"AAAA,4BAA4B;EAC1B;IACE,aAAa;IACb,4BAA4B;EAC9B;;EAEA;IACE,UAAU;IACV,wBAAwB;IACxB,0CAA0C;EAC5C;;EAEA,+BAA+B;EAC/B;IACE,UAAU;IACV,wBAAwB;EAC1B;EACA;IACE,UAAU;IACV,2BAA2B;IAC3B,0CAA0C;EAC5C;;EAEA;;;;KAIG","sourcesContent":["/* For new items fading in */\r\n  .fade-slide-enter {\r\n    opacity: 0.25;\r\n    transform: translateY(-12px);\r\n  }\r\n\r\n  .fade-slide-enter-active {\r\n    opacity: 1;\r\n    transform: translateY(0);\r\n    transition: opacity 400ms, transform 400ms;\r\n  }\r\n  \r\n  /* For old items sliding down */\r\n  .fade-slide-exit {\r\n    opacity: 1;\r\n    transform: translateY(0);\r\n  }\r\n  .fade-slide-exit-active {\r\n    opacity: 0;\r\n    transform: translateY(20px);\r\n    transition: opacity 500ms, transform 500ms;\r\n  }\r\n  \r\n  /* .fade-slide-exit-active {\r\n    opacity: 0;\r\n    transform: translateY(-20px);\r\n    transition: opacity 500ms ease-in, transform 500ms ease-in;\r\n  } */\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
